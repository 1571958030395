.logo-uni-mar {
    height: 40px;
    width: 132px;
}

.footer_bg {
    /* background-color: black; */
    background-image: url("../assets/uniglobemar/footer_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.social_icons img {
    height: 25px;
    width: 25px;
}

.navbar-unimar-bg {
    background-color: transparent;
    border-bottom: 1px solid rgb(137 127 127 / 50%);
    /* border-bottom: 1px solid white; */
    transition: background-color 0.3s ease;
}

.navbar-scrolled {
    background-color: rgb(75 51 110 / 60%);
    /* Semi-transparent purple */
    backdrop-filter: blur(10px);
    /* Glassy blur effect */
    -webkit-backdrop-filter: blur(10px);
    /* Safari support */
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    /* Optional subtle border */
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
    /* Smooth transitions */
}


.btn-main-mar {
    width: 155px;
    height: 51px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #8D41FF;
    align-items: center;
    color: white;
}

.btn-main-mar:hover {
    background-color: #6BFFE3;
    color: black;
    border: 1px solid #6BFFE3;
}

.main_text_mar {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    /* font-family: "Barlow Condensed"; */
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 88px;
    /* 100% */
    letter-spacing: -2px;
    text-transform: capitalize;
}

@keyframes circleEntry {
    0% {
      transform: translateY(-100%); /* Start above the viewport */
      opacity: 0; /* Hidden initially */
    }
    50% {
      transform: translateY(50vh); /* Move to the middle of the viewport */
      opacity: 1; /* Fully visible */
    }
    100% {
      transform: translateY(0); /* Back to the top */
      opacity: 1; /* Stay visible */
      /* transform: translateY(0); */
    }
  }
  
  .circle_round {
    width: 300px;
    height: 300px;
    flex-shrink: 0;
    border-radius: 50%; /* Ensures it's a perfect circle */
    background: rgba(141, 65, 255, 0.3);
    position: absolute;
    top: -5%; /* Start from the top */
    left: 20%;
    /* transform: translateY(-100%); Initial position above the screen */
    opacity: 0; /* Initially hidden */
    animation-fill-mode: forwards; /* Retain final state after animation ends */
    z-index: 10; /* Keep it above other elements */
  }
  
  .circle_round.animate {
    animation: circleEntry 3s ease-in-out; /* Smooth animation */
    opacity: 1;
  }
  
  
  
  
  
  
/* .circle_round {
    width: 300px;
    height: 300px;
    flex-shrink: 0;
    border-radius: 300px;
    background: rgba(141, 65, 255, 0.30);
    position: absolute;
    top: -5%;
    left: 20%;
} */


.hero-section {
    position: relative;
}

.hero-slide {
    background-size: cover;
    background-position: center;
    height: 100vh;
    /* Adjust height as needed */
    position: relative;
}

.mar_head {
    color: #000;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
    text-transform: capitalize;
    text-align: start;
}

.mar_head_com {
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
    text-transform: capitalize;
    text-align: start;
}

.text_mar {
    color: #8D41FF;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.4px;
    text-transform: capitalize;
}

.para_mar {
    /* color: var(--Detail-Color, #A09BAA); */
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: 25px;  
    /* 125% */
    letter-spacing: -0.32px;
    text-transform: capitalize;
}

.bg_about {
    background-image: url(../assets/uniglobemar/about_bg.png);
    /* background-position: ; */
    background-size: contain;
    background-repeat: no-repeat;
}

.card_b_mar {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
}

.card_b_mar:hover {
    border-radius: 10px;
    background: #8D41FF;
    color: white;
    cursor: pointer;
}

.card_b_mar:hover .para_mar {
    color: white;
}

.card_b_mar:hover img {
    filter: brightness(0) invert(1);
    /* Turns the image white */
}

.card_b_mar_co {
    border-radius: 10px;
    background: #F6F4F9;
}

.box_choose {
    max-width: 650px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bor_mar {
    border-bottom: 1px solid #D9D9D9;
    /* height: 300px; */
    color: var(--Detail-Color, #A09BAA);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.6px;
    text-transform: capitalize;
}

.clr_mar {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.6px;
    text-transform: capitalize;
}

.grey-circle {
    width: 10px;
    height: 10px;
    background-color: #ddd;
    border-radius: 50%;
    margin: 0 20px;
}

.bg-circl {
    background-color: #8D41FF;
}

.zoom-icon {
    transition: transform 0.3s ease-in-out;
    /* Smooth transition */
    cursor: pointer;
}

.zoom-icon:hover {
    transform: scale(1.7);
    /* Scale up on hover */
}

.zoom-icon:hover {
    transform: scale(0.8);
    /* Scale down effect if needed (optional) */
}

.content-div {
    order: 1;
    /* Default order for the content-div */
}

.side-div {
    order: 2;
    /* Default order for the side-div */
}

.icon_mar {
    background-color: white;
    height: 25px;
    width: 25px;
    border-radius: 20px;
    padding: 4px;
    color: black;
}

.para_mar_fo {
    color: #A09BAA;
    /* font-family: "Barlow Condensed"; */
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.3px;
    text-transform: capitalize;
}

.border-bottom-fo {
    border-bottom: 1px solid rgba(246, 244, 249, 0.50);
}

.para_mar_work{
    font-size: 15px;
}

.desktop_mob{
    width: 1rem;
}

@media screen and (max-width: 600px) {
    .desktop_mob{
        width: 0.5rem;
    }
    .bor_left_fo {
        border-left: none;
        height: auto;
    }

    .height_web {
        height: auto;
    }

    .main_text_mar {
        font-size: 28px;
    }

    .mar_head {
        font-size: 27px;
    }

    .mar_head_com {
        font-size: 25px;
    }

    .circle_round {
        width: 150px;
        height: 150px;
        left: 50%;
    }

    .main_text_mar {
        line-height: 18px;
    }

    .box_choose {
        display: flex;
        flex-direction: column;
    }

    .content-div {
        order: 2;
    }

    .side-div {
        order: 1;
    }
}