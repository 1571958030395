
.carousel-container {
  text-align: center;
  padding: 2rem;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg_img {
  background-image: url(../../../assets/uniglobemar/hero1.png) !important;
  background-size: cover !important;
  background-position: center !important;
  transition: "background-image 1s ease-in-out";
  min-height: 100vh !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  background-repeat: no-repeat !important;

}

.carousel-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.carousel-item {
  background: #fff;
  /* border-radius: 8px; */
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.carousel-item h3 {
  color: #333;
  margin-bottom: 0.5rem;
}

.carousel-item p {
  color: #666;
  font-size: 0.9rem;
}

.custom-prev,
.custom-next {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  width: 65px;
  height: 65px;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  border: 1px solid #646060;
  font-size: 30px;
  color: #646060;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-prev {
  /* left: -50px; */
  right: 40px;
  top: 60%;

}

.custom-next {
  /* right: -50px; */
  right: 40px;
  top: 50%;

  /* bottom: 20px; */
}

.custom-prev:hover,
.custom-next:hover {
  background-color: #6BFFE3;
  color: black;
}


.custom-next-dis,
.custom-prev-dis {
  background-color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  /* border: 1px solid #646060; */
  font-size: 25px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-prev-dis {
  left: 46%;
  top: 105%;
}

.custom-next-dis {
  left: 51%;
  top: 105%;
}

.custom-next-dis-com,
.custom-prev-dis-com {
  background-color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  /* border: 1px solid #646060; */
  font-size: 25px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.custom-prev-dis-com {
  left: 46%;
  top: 105%;

}

.custom-next-dis-com {
  left: 51%;
  top: 105%;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  25% {
    opacity: 1;
    transform: translateY(0);
  }
  75% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.animated-text {
  animation: fadeInOut 5s infinite; 
}
.animated-text-2{
  animation: fadeInOut 6s infinite;
}


@media screen and (max-width:600px) {
  .custom-prev-dis-com {
    left: 34%;
  }

  .custom-prev-dis{
    left: 34%;
  }

  .custom-next-dis-com {
    left: 50% ;
  }
  .custom-prev{
    top: 85%;
    background-color: white;
  }
  .custom-next{
    top: 75%;
    background-color: white;
  }
}




