@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-image-gallery/styles/css/image-gallery.css";

@import "./styles/unihomes.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a{
  text-decoration: none !important;
}
.modal-body{
  padding: 0px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.text-card {
  color: #d4af37;
  font-size: 24px;
  font-weight: 600;
  line-height: 30.24px;
  text-align: left;
  text-decoration-skip-ink: none;
}
.color {
  color: #d4af37;
}
.App-link {
  color: #61dafb;
}

.navbar-bg {
  background-color: #303030;
}

.logo {
  width: 90px;
  height: 90px;
}

.btn-main {
  background-color: #d4af37;
  color: white;
  border-radius: 6px;
}

.btn-main:hover {
  background-color: #303030;
  color: #d4af37;
  border: 1px solid #d4af37;
}

.btn-form {
  background-color: #303030;
  border-radius: 6px;
  color: white;
}

.nav_text {
  /* font-family: Outfit; */
  font-size: 16px;
  font-weight: 400;
  line-height: 20.16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.bg_hero {
  background-image: url(./assets/bg_hero.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.main_text {
  /* font-family: Outfit; */
  font-size: 64px;
  font-weight: 700;
  /* line-height: var(--DisplayLargeLineHeight); */
  text-align: left;
  /* text-underline-position: from-font; */
  text-decoration-skip-ink: none;
}

.card {
  border-radius: 10px;
}

.form-label {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 12.6px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.form-control {
  border-radius: 6px;
}

.card_b {
  border: 1px solid #3030301a;
  background-color: white;
}
.bg_discover {
  background-image: url(./assets/discover_bg.png);
  /* background-position: center; */
  /* background-size: cover; */
  background-repeat: no-repeat;
}
.golden-border {
  border-right: 1px solid #ebe2fa;
}

.btn-arrow svg {
  margin-top: -3px;
  margin-left: 2px;
}

.bg-color {
  border: 1px solid #3030301a;
}

.slick-slide {
  display: flex;
  justify-content: center;
}
.slick-slide div {
  /* margin: 5px !important; */
  /* border-radius: 10px; */
}

.icons {
  margin: 10px;
  align-items: center;
  background-color: #d4af37;
  border-radius: 50%;
  display: flex;
  height: 35px;
  justify-content: center;
  width: 35px;
}
.border_top {
  border-top: 1px solid #d4af3780;
}
.bg-color2 {
  background-color: #d4af37;
}

.app__navbar-smallscreen {
  display: none;
  z-index: 100 !important;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  transition: 0.5s ease;

  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: #ffff;
  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  list-style: none;
  color: #fff;
  /* font-size: 20px; */
  height: 100vh;
  overflow-y: scroll;
}

.app__navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 2rem;
  text-align: center;
  /* font-family: var(--font-base); */
}

.app__navbar-smallscreen_links a {
  text-decoration: none;
  font-size: 18px;
  color: white;
  /* background: linear-gradient(
    135deg,
    #b38728,
    #fcf6ba,
    #bf953f,
    #fbf5b7,
    #aa771c
  ); */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.row{
  margin: 0px !important;
}
@media screen and (max-width: 600px) {
  .main_text {
    font-size: 30px;
  }
  .left-arrow {
    margin-left: 10px;
    z-index: 10000;
  }
  .right-arrow {
    margin-right: 10px;
    z-index: 10000;
  }
}


