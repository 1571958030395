:root {
  --primary-color: #83ab40;
  --secondary-color: #445725cc;
}

.hero-section {
  /* background: url(../assets/unihomes/hero.png); */
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  /* display: flex; */
  /* align-items: center; */
  padding-top: 100px;
}

.heading-uni-main {
  font-size: 54px;
  font-weight: 700;
  color: white;
}

.navbar-uni-bg {
  background-color: var(--secondary-color);
}

.navbar-divider-uni {
  height: 100px;
}

.logo-uni {
  height: 85px;
}

.btn-uni-main {
  background-color: var(--primary-color);
  color: white;
  border-radius: 6px;
  font-size: 1.25rem;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.btn-uni-main:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.bg_hero {
  background-image: url(../assets/unihomes/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.main_text {
  /* font-family: Outfit; */
  font-size: 64px;
  font-weight: 700;
  /* line-height: var(--DisplayLargeLineHeight); */
  text-align: left;
  /* text-underline-position: from-font; */
  text-decoration-skip-ink: none;
}

.card {
  border-radius: 10px;
}

.form-label {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 12.6px;
  text-align: left;
  text-decoration-skip-ink: none;
}

.form-control {
  border-radius: 6px;
}

.divider {
  width: 160px;
  margin-top: 0px;
  margin-bottom: 10px;
  border: 2px solid var(--primary-color);
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.icons-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  right: 0px;
  top: 20px;
  background-color: var(--primary-color);
  padding: 10px;
  gap: 10px;
  border-radius: 10px 0px 0px 10px;
  -webkit-border-radius: 10px 0px 0px 10px;
  -moz-border-radius: 10px 0px 0px 10px;
  -ms-border-radius: 10px 0px 0px 10px;
  -o-border-radius: 10px 0px 0px 10px;
}

.facility-card {
  background: #f2f6eb;
}

.facility-card:hover {
  background: var(--primary-color);
  color: #fff;
}

.project-overview {
  background-image: url(../assets/unihomes/overview.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.location-overview {
  background-image: url(../assets/unihomes/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.video {
  width: 100vw;
  height: auto;
  min-height: 700px;
}

.footer {
  background-color: var(--primary-color);
  color: #fff;
}

.btn-form:hover,
.left-arrow:hover,
.right-arrow:hover {
  background-color: var(--primary-color);
  color: black;
}

.left-arrow {
  background-color: var(--secondary-color);
  border-radius: 12px !important;
  margin-left: 20px;
  z-index: 100 !important;
}

.right-arrow {
  background-color: var(--secondary-color);
  border-radius: 12px !important;
  margin-right: 20px;
  z-index: 100 !important;
}

.hero-section-div {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  transition: background-image 0.8s ease-in-out, opacity 0.8s ease-in-out;
  opacity: 1;
}

.hero-section.fade-out {
  opacity: 0;
  /* Smooth fade-out */
}

.hero-section.fade-in {
  opacity: 1;
  /* Smooth fade-in */
}

/* .car_mob {
  width: "100%";
  height: 450px;
  user-select: "none";
}

.img_car {
  height: "100%";
  width: "100%";
  object-fit: "contain";
  border-radius: 0;
} */
.mobile{
  height: 450px;
}
@media screen and (max-width: 600px) {
  .logo-uni{
    height: 60px !important;
  }
  .mobile{
    height: 300px;
  }
  .model_tit{
    font-size: 15px;
    font-weight: bold;
  }
  /* .car_mob {
    width: "100%";
    height: 300px;
    user-select: "none";
  }

  .img_car {
    height: "100%";
    width: "100%";
    object-fit: "cover";
    border-radius: 0;
  } */

  .heading-uni-main {
    margin-top: 120px;
  }

  .main_text {
    font-size: 30px;
  }

  .left-arrow {
    margin-left: 10px;
    z-index: 10000;
  }

  .right-arrow {
    margin-right: 10px;
    z-index: 10000;
  }
}